<template>
  <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showSecondOverlay === 'results'" class="w-full h-auto md:h-full fixed top-0 md:flex md:flex-row md:justify-center md:items-center md:inset-0 z-50 font-arial text-new-black">
      <div class="relative w-full md:w-5/12 md:min-w-100 h-screen min-h-screen md:min-h-0 md:h-auto md:max-h-80  md:overflow-y-auto md:bg-new-yellow md:rounded-t-3xl md:rounded-3xl md:p-5 pointer-events-auto scrollbar flex flex-col">

        <!-- Content -->
        <div class="w-full flex-grow flex-shrink-0"></div>
        <div class="w-full h-auto bg-new-yellow rounded-t-3xl p-5">

          <!-- Heading -->
          <div class="mt-6 font-faro font-bold text-3xl">Auswertung</div>
          <div class="mt-5">Du hast {{ totalCorrect }} von {{ max }} {{ resultText }}.</div>
          <div class="w-full mt-10 flex flex-row justify-center items-center">
            <div class="w-1/2">
              <circleProgress class="w-full h-full" :done="totalCorrect" :total="max" />
            </div>
          </div>


        </div>

        <div class="w-full p-5 flex-shrink-0 bg-new-yellow">
          <customButton class="mt-3" label='Fragen nochmal anschauen' @click="review"/>
          <customButton class="mt-3" label='Neues Training starten' @click="navigate"/>
        </div>
        <!-- Content -->


      </div>
    </div>
  </transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// SVG
import circleProgress from '../../../assets/svg/circleProgress.vue';

// Components
import customButton from '../../../symbols/customButton.vue';

export default {
  name: 'results',
  props: ['resultText', 'max', 'saveType'],
  mixins: [slideInOutBottom, iosVibrations],
  components: { customButton, circleProgress },

  computed: {
    showSecondOverlay() {
      return this.$store.state.layout.showSecondOverlay;
    },
    totalCorrect() {
      if (this.saveType === 'Pfadaufgabe') {
        let totalCorrect = 0;
        const { progress } = this.$store.state.training.currentTraining;
        if (progress) {
          progress.forEach((exercise) => {
            if (exercise.result === 'success') totalCorrect += 1;
          });
        }
        return totalCorrect;
      }
      return this.$store.state.training.currentTraining.totalCorrect;
    },
  },

  methods: {
    closeResults() {
      this.iosVibrate(0);
      this.$store.commit('showSecondOverlay', null);
    },
    review() {
      this.$store.commit('addToggle', {
        storeID: 'review', storeContainer: 'training', storeWrapper: 'settings', value: true,
      });
      this.$store.commit('showSecondOverlay', null);
    },

    navigate() {
      this.$store.commit('showSecondOverlay', null);
      this.$store.dispatch('saveTraining', { type: this.saveType });
      this.$router.replace({ name: 'training' });
    },
  },
};
</script>
